import { useApolloClient } from '@apollo/client';
import { StackNavigationOptions } from '@react-navigation/stack';
import { useEffect } from 'react';
import { Platform } from 'react-native';

import { RoundedSectionNavigationOptions } from '@oui/app-core/src/components/RoundedSection';
import { DEFAULT_HEADER_MODE, SESSION_TIMEOUT } from '@oui/app-core/src/constants';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { createSessionTimeoutStackNavigator } from '@oui/app-core/src/lib/createSessionTimeoutNavigator';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { setDeviceInfo } from '@oui/app-core/src/lib/setDeviceInfo';
import { AccountSettings } from '@oui/app-core/src/screens/AccountSettings';
import Auth from '@oui/app-core/src/screens/Auth';
import { CreateTestUser } from '@oui/app-core/src/screens/CreateTestUser';
import LocalAuthenticationPrompt from '@oui/app-core/src/screens/LocalAuthenticationPrompt';
import { TermsAndPrivacy } from '@oui/app-core/src/screens/TermsAndPrivacy';
import { useTheme } from '@oui/app-core/src/styles';
import { StaticReview } from '@oui/app-static/src/screens/StaticReview';
import { StaticSession } from '@oui/app-static/src/screens/StaticSession';
import { StaticTabNavigator } from '@oui/app-static/src/StaticTabNavigator';

import { RootStackParamList } from '@src/types/navigation';

import { TabNavigator } from './components/TabNavigator';
import { Confidentiality } from './screens/Confidentiality';
import { Connected } from './screens/Connected';
import { FinishRegistering } from './screens/FinishRegistering';
import { Welcome } from './screens/Welcome';

const Account = () => <AccountSettings tabScreen />;

const Stack = createSessionTimeoutStackNavigator<RootStackParamList>(SESSION_TIMEOUT);
export const RootNavigator = () => {
  const { scheme, theme } = useTheme();
  const { data: user, isLoggedIn } = useCurrentUser();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const { $t } = useI18n();
  const apollo = useApolloClient();

  const [hasSeenLocalAuthenticationPrompt] = usePersistedState(
    'SeenLocalAuthenticationPrompt',
    false,
  );

  let homePath: 'home' | 'homeStatic' = 'home';
  if (user?.user?.role?.productStatic) {
    homePath = 'homeStatic';
  }

  useEffect(() => {
    if (isLoggedIn) {
      void setDeviceInfo(apollo);
    }
  }, [apollo, isLoggedIn]);

  return (
    <Stack.Navigator
      {...Platform.select({ web: { cardStyle: { flex: 1 } } })} // So web screens grow properly
      screenOptions={() => {
        const options: StackNavigationOptions = {
          headerStyle: {
            height: defaultHeaderHeight,
          },
          headerBackTestID: 'header-back',
          headerTruncatedBackTitle: $t({ id: 'App_backButtonLabel', defaultMessage: 'Back' }),
          headerTintColor: theme.color.primary100,
          headerTitleStyle: { color: theme.color.dark, fontFamily: 'OpenSansSemiBold' },
          headerMode: DEFAULT_HEADER_MODE,
        };
        return options;
      }}
    >
      {isLoggedIn ? (
        <>
          {hasSeenLocalAuthenticationPrompt ? null : (
            <Stack.Screen
              name="LocalAuthenticationPrompt"
              component={LocalAuthenticationPrompt}
              options={{ headerShown: false }}
            />
          )}
          {homePath === 'home' ? (
            <Stack.Screen
              name="home"
              component={TabNavigator}
              options={{ headerShown: false, title: '' }}
            />
          ) : (
            <Stack.Screen name="homeStatic" options={{ headerShown: false, title: '' }}>
              {() => <StaticTabNavigator AccountSettings={Account} />}
            </Stack.Screen>
          )}
          <Stack.Screen name="TermsAndPrivacy" component={TermsAndPrivacy} />
          <Stack.Screen
            name="Confidentiality"
            component={Confidentiality}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FinishPatientRegistration"
            component={FinishRegistering}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="StaticSession"
            component={StaticSession}
            options={({ route }) => ({
              headerTitle: route.params.title,
              headerBackTitleVisible: false,
            })}
          />
          <Stack.Screen
            name="StaticReview"
            component={StaticReview}
            options={RoundedSectionNavigationOptions({
              defaultHeaderHeight,
              headerTintColor: theme.color.gray100,
              tintColor: theme.color.primary100,
              backgroundColor: 'white',
            })}
          />
        </>
      ) : (
        <>
          {Auth({ $t, Screen: Stack.Screen, scheme })}
          <Stack.Screen
            name="CreateTestUser"
            component={CreateTestUser}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false }} />
          <Stack.Screen name="Connected" component={Connected} />
        </>
      )}
    </Stack.Navigator>
  );
};
