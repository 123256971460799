import { useNavigation } from '@react-navigation/native';

import { Learn as LearnScreen } from '@oui/app-core/src/screens/Learn';
import { useTheme } from '@oui/app-core/src/styles';
import { useNavigateToStaticSessionHook } from '@oui/app-static/src/hooks/useNavigateToStaticSessionHook';

import { TabScreenProps } from '@src/types/navigation';

export function Learn(_props: {}) {
  const navigation = useNavigation<TabScreenProps<'Learn'>['navigation']>();
  const { navigateToStaticSession } = useNavigateToStaticSessionHook({ navigation });
  const { theme } = useTheme();

  return (
    <LearnScreen
      progressBackgroundColor={theme.color.accent100}
      onSessionCardPress={(session) =>
        navigateToStaticSession(
          { name: session.session.name, contentType: session.session.contentType },
          'sessions',
        )
      }
    />
  );
}
